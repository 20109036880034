@import '../../assets/scss/variable.scss';

.listWines {
  text-align: center;
  width: 100%;
  padding: 2rem 0;
  
  .add {
    background-color: $primary;
    border: none;
    border-radius: 10em;
    color: #FFF;
    cursor: pointer;
    padding: 1em 2.5em;
    position: fixed;
    right: 2rem;
    top: 2rem;
    max-width: 20rem;

    img {
      margin-right: 1em;
      width: 15%;
      vertical-align: middle;
    }

    &:hover {
      background-color: $primaryAccent1;
    }
  }

  .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 1em;
    width: 100%;

    .itemWine {
      cursor: pointer;
      width: calc(100% / 6);
      text-align: center;
      padding: 1rem;

      .content {
        background: #fff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        overflow: hidden;
        border-radius: 0;
        padding: 1.5rem 1rem;
        text-align: center;
        height: 18rem;
        display: block;

        img {
          max-height: 10rem;
          height: 10rem;
          display: block;
          margin: 0 auto;
          text-align: center;
        }
      }
    }
  }
}

.ManageWine {
  width: 100%;
  padding: 2rem 5rem;
  background-color: white;

  .content {
    display: inline-flex;
    .customSelect {
      margin: 0.5em 0;
    }
    .contentLabel {
        margin-right: 3.5rem;
        max-width: 33rem;
        text-align: center;

        img {
          border: 1px dashed #000;
          border-radius: 10px;
          padding: 1em;
          cursor: pointer;
          width: auto;
          height: auto;
          max-width: 200px;
          max-height: 200px;
          float: left;
        }

        .etq {
          margin-left: 2em;
        }
    }
    .contentText {
      p {
        margin: 0.5em 0;
      }
      .name {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #484848;
        color: #484848;
        margin-bottom: 0.5em;
      }
      span {
        font-weight: bold;
      }

      .vuforia {
        .add, .update, .delete {
          background-color: #6f51ff;
          border: none;
          border-radius: 10rem;
          color: #FFF;
          cursor: pointer;
          padding: 1.5rem 5rem;
          margin-top: 1rem;    
        }
        .delete {
          background-color: #EF4136;
          display: block;
        }
        button:disabled {
          background-color:rgb(221, 221, 221);
          cursor: inherit;
        }
      }
    }
  }
  .delete {
    button {
      background-color: #EF4136;
      border: none;
      border-radius: 50px;
      color: #fff;
      cursor: pointer;
      margin-top: 2em;
      padding: 1em 2em;
    }
  }
}

.lds-roller {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $primary;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
